.footer {
  background: #1e3f94;
  padding: 20px;
}
.footer_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 0.9vw;
  color: #ffffff;
}
.know_more {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 1vw;
  border-bottom: 2px solid white;
  color: #ffffff;
  width: 30%;
  text-align: center;
}
.quick_link {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-size: 1.2vw;

  color: #ffffff;
  width: 30%;
  text-align: center;
  margin: 0 auto;
}
.quick1_links {
  width: 40%;
}
.quick_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #ffffff;
  text-align: center;
}
.footer_border {
  border-bottom: 1px solid white;
  padding: 20px;
}
.footer_logo {
  width: 8vw;
}
.contact_para {
  background: #fff;
  color: #1e3f94;
  padding: 10px;
  width: 50%;
  margin: 0 auto;
  border-radius: 6px;
}

@media only screen and (max-width: 990px) {
  .footer_logo {
    width: 15vw;
  }
  .footer_para {
    font-size: 2vw;
    text-align: center;
  }
  .know_more {
    margin: 0 auto;
    font-size: 2.5vw;
    width: 20%;
  }
  .quick_link {
    font-size: 2.5vw;
    width: 20%;
  }
  .quick1_links {
    width: 20%;
  }
  .quick_para {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 600px) {
  .footer_logo {
    width: 25vw;
  }
  .footer_para {
    font-size: 3vw;
    text-align: center;
  }
  .know_more {
    margin: 0 auto;
    font-size: 3.5vw;
    width: 20vw;
  }
  .quick_link {
    font-size: 3.5vw;
    width: 20vw;
  }
  .quick1_links {
    width: 20vw;
  }
  .quick_para {
    font-size: 3vw;
  }
}
