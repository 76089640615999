.home_Banner {
  width: 100%;
}
.Registration_img {
  width: 100%;
  position: relative;
  height: 100%;
}
.center{
  overflow-x:hidden;
}
.registration_head {
  position: absolute;
  bottom: -10px;
  background: #3c1b99;
  color: white;
  font-size: 1.5vw;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
}
.rectangle_images {
  width: 100%;
  height: 100%;
}
.about_images {
  width: 95%;
}
.about_head {
  color: #1e3f94;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2.08vw;
}
.chairman_head {
  color: #1e3f94;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2.08vw;
  margin-left: 5%;
}
.about_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  width: 80%;
  text-align: justify;
}
.attendPara {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  width: 80%;
  text-align: justify;
}
.chairman_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000;
  width: 80%;
  margin-left: 5%;
  text-align: justify;
}
.chairman_images {
  width: 100%;
  /* height: 0%; */
  display: flex;
  justify-content: flex-end;
}
.Special_class {
  font-size: 3vw;
}
.Special_Date {
  font-size: 0.9vw;
}
.clock {
  background: #ffffff;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.35);
  width: 65%;
  margin: 0 auto;
  padding: 20px;
}
.btn_Register {
  border: none;
  outline: none;
  background: #1e3f94;
  border-radius: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.1vw;
  color: #ffffff;
  width: 12.86vw;
  padding: 10px;
}
.Conference_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 2.08vw;
  color: #1e3f94;
}

.timer_para {
  border-right: 2px solid black;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}
.boldHeading{
  font-weight: 900;
}
.home_banner {
  background: url('../assests/contact/contact_background.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact_logos {
  width: 10.65vw !important;
}
.organizede {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.56vw;
  color: #ffffff;
}
.bangok_heads {
  font-family: "Georgia";
  font-style: normal;
  font-weight: 700;
  font-size: 1.56vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

@media only screen and (max-width: 990px) {
  .registration_head {
    height: 8vh;
    font-size: 4vw;
  }
  .about_head {
    font-size: 4vw;
  }
  .about_para {
    font-size: 2vw;
  }
  .chairman_head {
    font-size: 4vw;
  }
  .chairman_para {
    font-size: 2vw;
  }
  .chairman_images {
    width: 80vw;
    margin: 0 auto;
  }
  .about_images {
    width: 80vw;
    margin: 0 auto !important;
  }
  .attendPara {
    font-size: 2vw;
  }
  .Conference_head {
    font-size: 4vw;
  }
  .clock {
    width: 90%;
  }
  .Special_class {
    font-size: 4vw;
  }
  .Special_Date {
    font-size: 2vw;
  }
  .btn_Register {
    width: 25vw !important;
    font-size: 2vw;
  }
  .timer_para {
    padding: 10px;
    border-right: 0px solid black;
  }
  .organizede,
  .bangok_heads {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 600px) {
  .registration_head {
    height: 8vh;
    font-size: 4vw;
  }
  .about_head {
    font-size: 6vw;
    text-align: center;
  }
  .about_para {
    font-size: 3vw;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
  .chairman_head {
    font-size: 6vw;
    text-align: center;
  }
  .chairman_para {
    font-size: 3vw;
    width: 90%;
    margin: 0 auto;
  }
  .chairman_images {
    width: 80vw;
    margin: 0 auto;
  }
  .about_images {
    width: 90vw;
    margin: 0 auto !important;
  }
  .attendPara {
    font-size: 3vw;
    width: 90%;
    margin: 0 auto;
  }
  .Conference_head {
    font-size: 4vw;
  }
  .clock {
    width: 90%;
  }
  .Special_class {
    font-size: 5vw;
  }
  .Special_Date {
    font-size: 3vw;
  }
  .btn_Register {
    width: 25vw !important;
    font-size: 2.5vw;
  }
  .timer_para {
    padding: 0px;
    border-right: 0px solid black;
  }
  .organizede,
  .bangok_heads {
    font-size: 5vw;
  }
  .contact_logos {
    width: 50vw !important;
  }
  .world_data {
    width: 100vw;
  }
  .date_home {
    width: 100vw;
  }
}
