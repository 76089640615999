.commitee_banner {
  background: url('../assests/commitee/commitee.png');
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
}
.center{
  overflow-x: hidden;
}
.commitee_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 4.16vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.commitee_head_organize {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2.08vw;

  color: #1e3f94;
}
.commitee_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
}

@media only screen and (max-width: 990px) {
  .commitee_head {
    font-size: 6vw;
  }
  .commitee_head_organize {
    font-size: 3vw;
  }
  .commitee_para {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 600px) {
  .commitee_head {
    font-size: 7vw;
  }
  .commitee_head_organize {
    font-size: 4.5vw;
  }
  .commitee_para {
    font-size: 3vw;
  }
}
