.admin_panel_head,
.Accompany_head {
  font-family: "Lato";

  font-weight: 500;
  font-size: 2vw;
  color: #000000;
}
.admin_accordion_head {
  font-family: "Lato";
  font-weight: 500;
  font-size: 2vw;
  color: #000000;
}
.register_detail_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  color: #000000;
}
.formValid {
  font-size: 1.2vw;
}
.registration_head1 {
  background: #3c1b99;
  color: white;
  font-size: 1.5vw;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
}
.visibility_none {
  visibility: hidden;
}
.visible {
  min-height: 30vh;
}
.btn_variant_submitted {
  background-color: #1e3f94 !important;
  color: white !important;
  font-size: 1vw !important;
  margin-left: 0px;
}
@media only screen and (max-width: 990px) {
  .register_detail_head,
  .Accompany_head {
    font-size: 4vw;
  }
  .admin_accordion_head {
    font-size: 3vw;
  }
  .formValid {
    font-size: 2.5vw;
  }
  .registration_head1 {
    font-size: 3vw;
  }
  .btn_variant_submitted {
    font-size: 2vw !important;
  }
}

@media only screen and (max-width: 600px) {
  .register_detail_head,
  .Accompany_head {
    font-size: 6vw;
  }
  .admin_accordion_head {
    font-size: 3.5vw;
  }
  .formValid {
    font-size: 3.5vw;
  }
  .registration_head1 {
    font-size: 4vw;
  }
  .btn_variant_submitted {
    font-size: 4vw !important;
  }
}
