.award_detailsPage {
  display: flex;
  min-height: 40vh;
  background: url('../../assests/award/award.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.award_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 4.16vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}
.award_head_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  color: #000000;
  text-align: justify;
}
.award_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
}
.award_backup {
  background: url('../../assests/award/award2.png');
  min-height: 20vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}
.award_title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.82vw;
  color: #ffffff;
}
.btn_details1 {
  background: #1e3f94;
  border-radius: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2vw;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  padding-left: 20px !important;
  padding-right: 20px !important;
  padding: 10px;
}
@media only screen and (max-width: 990px) {
  .award_head_para {
    font-size: 3.5vw;
  }
  .award_para {
    font-size: 2vw;
  }
  .award_title {
    font-size: 2.5vw;
  }
  .btn_details1 {
    font-size: 3vw;
  }
}

@media only screen and (max-width: 600px) {
  .award_head_para {
    font-size: 5vw;
  }
  .award_para {
    font-size: 3vw;
  }
  .award_title {
    font-size: 3vw;
    text-align: center;
  }
}
