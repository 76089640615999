.scient_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
  margin-left: 5%;
  width: 80%;
}
.boldness {
  font-weight: 700;
}
.speaker_head{
  font-size: 1.1vw;
  margin-top: -5%;
}
.speaker_para{
  font-size: 0.8vw;
  margin: 0 auto;
}
.speaker_img {
  width: 80%;
}
.speaker_images{
  width: 10vw;
  margin-left: 6vw !important;
}
.width_max {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.text_fields {
  color: #1e3f94;
  font-size: 3vw;
}

@media only screen and (max-width: 990px) {

    .speaker_images {
      width: 100%;
      margin-left: 0vw !important;
    }
        .text_fields {
          color: #1e3f94;
          font-size: 7vw;
        }
                .speaker_para {
                  font-size: 100%;
                  margin: 0 auto;
                }
                                .speaker_head {
                                  font-size: 100%;
                                  margin-top: -15%;
                                }
}

@media only screen and (max-width: 600px) {
  .text_fields {
    color: #1e3f94;
    font-size: 9vw;
  }
}
