.navbar_logo {
  width: 7.34vw;
  height: 2.86vw;
}
.Navbar_text {
  font-size: 1.2vw;
  color: black;
  text-decoration: none;
  font-weight: 800;
}
.Navbar {
  background: white;
  padding: 10px;
  height: 6vw;
  position: sticky;
  top: 0;
}
.Navbar_text:hover {
  text-decoration: none;
  color: black;
}
.dowload_button {
  background: #1e3f94;
  border-radius: 3px;
  width: 11.97vw;
  height: 2.86vw;
  color: white;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  border: none;
  outline: none;
}
.Navbar_text:after {
  display: block;
  content: "";
  border-bottom: solid 2px #000;
  transform: scaleX(0);
  transition: transform 400ms ease-in-out;
}
.Navbar_text:hover:after {
  transform: scaleX(1);
}

.Navbar_text:after {
  transform-origin: 0% 50%;
}
.dropdown-toggle {
  color: black !important;
  font-weight: 800;
  font-size: 1.2vw;
}
.dropdown-item {
  color: black !important;
  font-size: 1.2vw;
}
.Navbar_text1 {
  background: #1e3f94;
  padding: 10px;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  padding-left: 20px;
  padding-right: 20px;
}
.Navbar_text1:hover {
  color: white;
  text-decoration: none;
}

.Navbar_text.active {
  border-bottom: solid 2px #000;
}
.navbar_fam.active {
  border-bottom: solid 2px #000;
}
.navbar_logo_expensive {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.mobile_container {
  display: none;
}

/* mobile Navbar */

@media only screen and (max-width: 990px) {
  .mobile_container {
    display: block;
    overflow-x: hidden !important;
    margin-bottom: 15vh;
  }

  .mobile_container .header_mobile {
    position: fixed !important;
    display: block;
    top: 0;
    left: 0;
    overflow-x: hidden !important;
    z-index: 999;
  }
  .content {
    padding: 40px 5% 20px;
    text-align: justify;
    max-height: 100%;
    color: #333;
    overflow-y: scroll;
  }
  .content img {
    width: 100%;
    position: relative;
    display: block;
    margin: 40px auto 30px;
  }

  /* End container/placeholder */

  /* Menu header_mobile */
  .header_mobile {
    background: #3a5396;
    overflow: hidden !important;
    height: 15vh;
    width: 100%;
    z-index: 1;
    position: fixed !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 0.4s ease-out,  1s ease-out;
  }
  .header_mobile.menu-open {
    height: 100%;
    background: #3a5396d0;
    transition: all 0.45s ease-out,  0.8s ease-out;
  }

  /* Menu List items */
  .mobile-menu {
    clear: both;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100vh;
  }
  .header_mobile ul.menu {
    position: relative;
    margin-bottom: 20vw;
    padding: 0px 70px 0;
    list-style: none;
  }

  .header_mobile ul.menu li.menu-item a {
    display: block;
    position: relative;
    color: #fff;
    text-align: right;
    text-decoration: none;
    font-size: 4vw;
    margin-left: -5%;
    line-height: 2.8;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
  }
  .header_mobile ul.menu li.menu-item {
    margin-top: 5px;
    opacity: 0;
    transition: opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition: transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      opacity 0.6s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.5s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  .header_mobile ul.menu li.menu-item:nth-child(1) {
    transition-delay: 0.35s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(2) {
    transition-delay: 0.3s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(3) {
    transition-delay: 0.25s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(4) {
    transition-delay: 0.2s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(5) {
    transition-delay: 0.15s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(6) {
    transition-delay: 0.1s;
  }
  .header_mobile ul.menu li.menu-item:nth-child(7) {
    transition-delay: 0.05s;
  }
  .header_mobile.menu-open ul.menu li.menu-item {
    opacity: 1;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(1) {
    transition-delay: 0.05s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(2) {
    transition-delay: 0.1s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(3) {
    transition-delay: 0.15s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(4) {
    transition-delay: 0.2s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(5) {
    transition-delay: 0.25s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(6) {
    transition-delay: 0.3s;
  }
  .header_mobile.menu-open ul.menu li.menu-item:nth-child(7) {
    transition-delay: 0.35s;
  }
  .hamburger {
    width: 7vw;
  }

  /* Menu Icon */
  .icon-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  .icon-container #menuicon {
    width: 20px;
    height: 10px;
    position: relative;
    display: block;
    margin: -4px auto 0;
    top: 50%;
  }
  .closed {
    display: none;
  }
  #menuicon .bar {
    width: 100%;
    height: 1px;
    display: block;
    position: relative;
    background: #fff;
    transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  }
  .hamburger {
    width: 5vw;
  }
  #menuicon .bar.bar1 {
    -webkit-transform: translateY(0px) rotate(0deg);
    transform: translateY(0px) rotate(0deg);
  }
  #menuicon .bar.bar2 {
    -webkit-transform: translateY(6px) rotate(0deg);
    transform: translateY(6px) rotate(0deg);
  }
  #menuicon .bar.bar3 {
    -webkit-transform: translateY(12px) rotate(0deg);
    transform: translateY(12px) rotate(0deg);
  }
  .menu-open .closed {
    display: block;
    width: 6vw;
    float: right;
  }
  .menu-open .icon-container .hamburger {
    display: none;
  }
  .menu-open .icon-container .logo {
    display: none !important;
  }
  .menu-open .icon-container #menuicon .bar {
    transition: all 0.4s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transition-delay: 0.1s;
  }
  .menu-open .icon-container #menuicon .bar.bar1 {
    -webkit-transform: translateY(4px) rotate(45deg);
    transform: translateY(4px) rotate(45deg);
  }
  .menu-open .icon-container #menuicon .bar.bar2 {
    -webkit-transform: translateY(3px) rotate(-45deg);
    transform: translateY(3px) rotate(-45deg);
  }
  .mobile_pallete {
    height: 15vh;
  }
  .navbar {
    display: none;
  }
  .logo {
    width: 13vw;
  }
  .dropdown-toggle {
    color: white !important;
    font-weight: 500;
    font-size: 4vw;
    margin-left: 20%;
  }
  .dropdown-item {
    color: black !important;
    font-size: 3.5vw;
  }
}
