@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&family=Poppins:wght@300&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
}

.linkALL {
  text-decoration: none;
  color: inherit;
}

.linkALL:hover {
  text-decoration: none;
  color: inherit;
}
