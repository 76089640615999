.login {
  background: url('../../assests/contact/contact_background.png');
  min-height: 100vh;
  background-size: cover;
  background-position: center;
}
.Login_background {
  background: #3a539665;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 3.125vw;
  color: white;
}
.login_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #ffffff;
  width: 26.35vw;
  text-align: justify;
}
.loginInput {
  outline: none;
  border: none;
  background: transparent;
  border-bottom: 2px solid rgba(255, 255, 255, 0.473);
  width: 35.26vw;
  color: white;
}
.loginButton {
  background: #deb959;
  width: 35.26vw;
  padding: 10px;
  outline: none;
  border: none;
  font-size: 1.1vw;
}
.errorMessage {
  color: red;
  text-align: center;
  font-size: 1.1vw;
  margin-top: 2%;
}
.bi-eye,
.bi-eye-slash {
  color: white;
}
.showbar_icon {
  margin-left: -5%;
}
.activeLoginLink {
  border-bottom: 2px solid white;
}
.userAcive {
  color: white;
}

@media only screen and (max-width: 990px) {
  .login_head {
    font-size: 6vw;
  }
  .login_para {
    font-size: 3vw;
    width: 70vw;
  }
  .loginInput {
    width: 70vw;
    font-size: 3vw;
  }
  .loginButton {
    width: 70vw;
    font-size: 3vw;
  }
  .login {
    padding-top: 20%;
  }
  .login_mobile_tab {
    padding-bottom: 20% !important;
  }
}

@media only screen and (max-width: 600px) {
  .login_head {
    font-size: 8vw;
  }
  .login_para {
    font-size: 5vw;
    width: 70vw;
  }
  .loginInput {
    width: 70vw;
    font-size: 4vw;
  }
  .loginButton {
    width: 70vw;
    font-size: 4vw;
  }
}
