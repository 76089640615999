.abstract_passage {
  background: url("../assests/abstract/Rectangle.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abstract_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 4vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.scientific_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2.08vw;
  margin-left: 2%;
  color: #1e3f94;
}

.scient_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  color: #000000;
  margin-left: 5%;
  width: 80%;
}

.bold_para {
  font-weight: 700;
}

.group_abstract {
  width: 70%;
}

.width_max {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.abstarct_updated_banner {
  background: url("../assests/abstract/Rectangle.png");
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 30vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.abstract_headed {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 4.16vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.userAbstract {
  font-family: "Lato";
  font-weight: 500;
  font-size: 2.3vw;
  color: #000000;
}

.textarea_abstract {
  height: 30vh;
}

.form_register_updated {
  border: none;
  outline: none;
  border-radius: 0px;
  width: 80%;
}

.form_register_updated1 {
  border: none;
  outline: none;
  border-radius: 0px;
  width: 73%;
}

.form_register_updated2 {
  border: none;
  outline: none;
  border-radius: 0px;
  width: 50%;
}

.Abstracted_para_updated {
  font-size: 0.7vw;
}

.loader {
  --R: 30px;
  --g1: #514b82 96%, #0000;
  --g2: #eeeeee 96%, #0000;
  width: calc(2*var(--R));
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  -webkit-mask: linear-gradient(#000 0 0);
  animation: l30 2s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  width: 50%;
  background:
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(var(--R) + 0.5*var(--R) - var(--R)) calc(var(--R) - 0.866*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) 0 calc(-1*var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(var(--R) - 0.5*var(--R) - var(--R)) calc(var(--R) - 0.866*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(-1*var(--R)) 0,
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866*var(--R) - var(--R)) calc(var(--R) + 0.5*var(--R) - var(--R));
  background-size: calc(2*var(--R)) calc(2*var(--R));
  background-repeat: no-repeat;
}

.loader::after {
  transform: rotate(180deg);
  transform-origin: right;
}

@keyframes l30 {
  100% {
    transform: rotate(-1turn)
  }
}

@media only screen and (max-width: 990px) {
  .scientific_head {
    font-size: 4vw;
  }

  .scient_para {
    font-size: 2.3vw !important;
  }

  .abstract_passage {
    min-height: 30vw;
  }

  .userAbstract {
    font-size: 4vw;
  }

  .Abstracted_para_updated {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 600px) {
  .scientific_head {
    font-size: 5vw;
  }

  .scient_para {
    font-size: 3vw !important;
    width: 90% !important;
  }

  .abstract_passage {
    min-height: 40vw;
  }

  .abstract_head {
    font-size: 5vw;
  }

  .Abstracted_para_updated {
    font-size: 2vw;
  }
}