.headedImage {
  background: url('../../assests/register/banner.png');
  background-size: cover;
  background-position: center;
  min-height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background_loader {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.671)
}

.loader {
  --R: 30px;
  --g1: #514b82 96%, #0000;
  --g2: #eeeeee 96%, #0000;
  width: calc(2*var(--R));
  aspect-ratio: 1;
  border-radius: 50%;
  display: grid;
  -webkit-mask: linear-gradient(#000 0 0);
  animation: l30 2s infinite linear;
}

.loader::before,
.loader::after {
  content: "";
  grid-area: 1/1;
  width: 50%;
  background:
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) + 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(var(--R) + 0.5*var(--R) - var(--R)) calc(var(--R) - 0.866*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) 0 calc(-1*var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(var(--R) - 0.5*var(--R) - var(--R)) calc(var(--R) - 0.866*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866*var(--R) - var(--R)) calc(var(--R) - 0.5*var(--R) - var(--R)),
    radial-gradient(farthest-side, var(--g2)) calc(-1*var(--R)) 0,
    radial-gradient(farthest-side, var(--g1)) calc(var(--R) - 0.866*var(--R) - var(--R)) calc(var(--R) + 0.5*var(--R) - var(--R));
  background-size: calc(2*var(--R)) calc(2*var(--R));
  background-repeat: no-repeat;
}

.loader::after {
  transform: rotate(180deg);
  transform-origin: right;
}

@keyframes l30 {
  100% {
    transform: rotate(-1turn)
  }
}

.register_WDCLogo {
  width: 12.29vw;
}

.para_worldDental {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.82vw;
  text-align: center;
  color: #ffffff;
}

.head_worldDental {
  font-family: "Lato";
  font-style: normal;
  font-weight: 800;
  font-size: 4.16vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.border_right {
  opacity: 0.5;
  border: 1px solid #000000;
  height: 5.729vw;
}

.border_Botom {
  border-bottom: 0.31vw solid #1e3f94;
  padding: 10px;
}

.ugRegister {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1.82vw;
  color: #000000;
}

.head_general {
  width: 52%;
  margin: 0 auto;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.56vw;
  color: #1e3f94;
}

.form_register {
  border: none;
  outline: none;
  border-radius: 0px;
  width: 50%;
}

.form_register_accompany {
  width: 52%;
}

.ug_amount {
  font-size: 1.3vw;
}

.age_input {
  border: none;
  outline: none;
  border-bottom: 1px solid black;
  border-radius: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.form_register2 {
  width: 49%;
}

.form_register1 {
  border: none;
  outline: none;
  border-radius: 0px;
  width: 49%;
}

.error_register {
  width: 50%;
}

.file_matter {
  font-size: 16px;
  background: white;
  width: 100%;
  outline: none !important;
  border: none;
}

::-webkit-file-upload-button {
  color: white !important;
  background: #1e3f94 !important;
  border: none !important;
  border-radius: 5px !important;
  border: none;
  outline: none !important;
}

.fileBlessing {
  border: 2px dashed black;
  width: 50%;
  margin: 0 auto;
  padding: 10px;
}

.submit_Form {
  background: #1e3f94;
  box-shadow: 0px 13px 27px -5px rgba(50, 50, 93, 0.25),
    0px 8px 16px -8px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  width: 52%;
  padding: 10px;
  font-size: 1vw;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.submit_Form:hover {
  background: #1e3f94;
}

.file_setting {
  width: 50%;
  margin: 0 auto;
}

.head_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  text-align: justify;
  color: #000000;
  width: 50%;
  margin: 0 auto;
}

.invalid_feedback {
  width: 50% !important;
  margin: 0 auto;
}

/* .invalid_feedback1{

} */
.agebar {
  position: relative;
}

.validThings {
  background: url('../../assests/register/bannerD.png');
  background-position: center;
  background-size: cover;
  min-height: 40vh;
  background-repeat: no-repeat;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 4.16vw;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
}

.success-message {
  color: green;
  font-size: 1vw;
  width: 50%;
  margin: 0 auto;
}

.error-message {
  color: #dc3545;
  font-size: 1vw;
  width: 50%;
  margin: 0 auto;
}

.error-message1 {
  color: #dc3545;
  font-size: 1vw;
  margin: 0 auto;
}

.invalid-feedback {
  font-size: 1vw !important;
}

.btn_details {
  background: #1e3f94;
  border-radius: 6px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.3vw;
  text-align: center;
  color: #ffffff;
  border: none;
  outline: none;
  width: 10vw;
  padding: 10px;
}

.btn_disabled.disabled {
  border-radius: 6px;
  width: 52%;
  padding: 10px;
  font-size: 1vw;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.conference_register {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 2.08vw;
  color: #1e3f94;
}

.package_main {
  background: url('../../assests/register/card1.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 34vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.package_main1 {
  background: url('../../assests/register/card2.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 34vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.package_main2 {
  background: url('../../assests/register/card3.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 34vw;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.pacakgeDetail_head {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.56vw;
  color: #ffffff;
  text-align: center;
  position: absolute;
  top: 3vw;
  width: 90%;
  transform: translate(-7vw, -0vw);
}

.packageDetail_para {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 1vw;
  text-align: center;
  color: #ffffff;
  flex-grow: 1;
  margin-bottom: 20px;
  position: absolute;
  width: 90%;
  transform: translate(-7vw, -0vw);
  top: 8vw;
}

.fixed_box {
  background: rgba(0, 0, 0, 0.68);
  padding: 0.5vw;
  text-align: center;
  position: absolute;
  top: 8vw;
  width: 90%;
  transform: translate(1vw, 15vw);
  left: 0px;
}

.price_chart {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 1.45vw;
  text-align: center;
  color: #ffffff;
  margin-bottom: 0px;

}

.linkALL {
  text-decoration: none;
  color: inherit;
}

.customFileInput::-webkit-file-upload-button {
  visibility: hidden !important;
}

.customFileInput::before {
  content: "Browse file" !important;
  background: #1e3f94;
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin-top: 0px;
  font-size: 1vw;
}

.css-1u9des2-indicatorSeparator {
  background-color: transparent !important;
}

.css-13cymwt-control {
  border: none !important;
  border-bottom: 1px solid black !important;
  border-radius: 0px !important;
}

.css-tj5bde-Svg {
  color: #000000 !important;
}

.accompany_bold {
  font-size: 1.2vw;
}

.password_type {
  position: relative;
}

.absolute_password {
  position: absolute;
  top: 0px;
  right: 0px;
}



@media only screen and (max-width: 990px) {
  .headedImage {
    min-height: 50vh;
  }

  .para_worldDental {
    font-size: 2.3vw;
  }

  .head_worldDental {
    font-size: 5vw;
  }

  .ug_amount {
    font-size: 2.4vw;
  }

  .age_input {
    margin: 0 auto;
  }

  .ugRegister {
    font-size: 3vw;
  }

  .head_general {
    font-size: 3vw;
    width: 60%;
  }

  .form_register1 {
    margin: 0 auto;
  }

  .form_register,
  .form_register1 {
    width: 60%;
  }

  .file_matter {
    width: 100%;
  }

  .submit_Form,
  .btn_disabled {
    width: 60%;
    font-size: 2.4vw;
  }

  .head_para {
    font-size: 2.3vw;
    width: 60%;
  }

  .details_head {
    font-size: 6vw;
  }

  .btn_details {
    font-size: 3vw;
    width: 25vw;
  }

  .conference_register {
    font-size: 3vw;
  }

  .package_main,
  .package_main1,
  .package_main2 {
    min-height: 50vw;
  }

  .pacakgeDetail_head {
    font-size: 3vw;
    position: inherit !important;
    transform: translate(0px, 0px);
  }

  .packageDetail_para {
    font-size: 2.4vw;
    position: inherit !important;
    transform: translate(0px, 0px);
  }

  .price_chart {
    font-size: 2.6vw;
  }

  .fixed_box {
    width: 80%;
    margin: 0 auto;
    position: inherit !important;
    transform: translate(0px, 0px);
  }

  .fileBlessing {
    width: 60% !important;
  }

  .error-message {
    font-size: 2vw;
    width: 60%;
  }

  .error-message1,
  .invalid-feedback {
    font-size: 2vw !important;
  }

  .file_setting {
    width: 60%;
  }

  .customFileInput::before {
    font-size: 2vw;
  }

  .form_register_accompany {
    width: 60%;
  }

  .accompany_bold {
    font-size: 2.2vw;
  }
}

@media only screen and (max-width: 600px) {
  .headedImage {
    min-height: 50vh;
  }

  .register_WDCLogo {
    width: 20vw;
  }

  .para_worldDental {
    font-size: 3.3vw;
  }

  .head_worldDental {
    font-size: 6vw;
  }

  .ug_amount {
    font-size: 3.4vw;
  }

  .ugRegister {
    font-size: 4vw;
  }

  .head_general {
    font-size: 5vw;
    width: 100%;
    text-align: center;
  }

  .form_register,
  .form_register1,
  .form_register2,
  .btn_disabled.disabled {
    width: 70%;
  }

  .btn_disabled.disabled {
    font-size: 3.4vw;
  }

  .file_matter {
    width: 100%;
  }

  .submit_Form,
  .btn_disabled {
    width: 70%;
    font-size: 3.4vw;
  }

  .head_para {
    font-size: 3.3vw;
    width: 70%;
  }

  .details_head {
    font-size: 8vw;
  }

  .btn_details {
    font-size: 5vw;
    width: 35vw;
  }

  .conference_register {
    font-size: 5vw;
  }

  .package_main,
  .package_main1,
  .package_main2 {
    min-height: 100vw;
  }

  .pacakgeDetail_head {
    font-size: 4vw;
  }

  .packageDetail_para {
    font-size: 3.4vw;
  }

  .price_chart {
    font-size: 3.6vw;
  }

  .fixed_box {
    width: 80%;
    margin: 0 auto;
  }

  .fileBlessing {
    width: 70% !important;
  }

  .error-message {
    font-size: 3.3vw;
    width: 70%;
  }

  .error-message1,
  .invalid-feedback {
    font-size: 3.3vw !important;
  }

  .file_setting {
    width: 70%;
  }

  .customFileInput::before {
    font-size: 3vw;
  }

  .form_register_accompany {
    width: 70%;
  }

  .accompany_bold {
    font-size: 3.2vw;
  }
}